/* src/styles.css */


body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.main-screen {
  text-align: center;
  padding: 20px;
}

.custom-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Make the custom icon circular if needed */
}

.flags {
  top: 50px;
  display: flex;
  margin-left: 100px;
}

.flag {
  width: 30px;
  height: 20px;
  margin-left: 10px;
}

/* Main Page Styles*/
.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.image-item {
  width: 150px;
  height: 150px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.image-item img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.add-icon {
  font-size: 4em;
}


.button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.app {
  font-family: Arial, sans-serif;
}

.addButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.home-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  margin-right: 5px; /* Add some space between the home icon and the flags */

}


/* src/AddCard.css */
.add-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.top-bar {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
}


.card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 90vw;  /* 90% of the viewport width */
  max-width: 400px;  /* Ensure it doesn't get too large on bigger screens */
  height: 80vh;  /* 80% of the viewport height */
  max-height: 700px;  /* Ensure it doesn't get too large on bigger screens */
}

.input-field {
  width: 90%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.input-field-translation {
  width: 90%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.imagine-button {
  background-color: #4CAF50;
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-top: 350px;
  margin-bottom: 10px;
}

.imagine-button:hover {
  background-color: #45a049;
}